import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "./image"

interface IProps {
  service: Service
}

interface Service {
  title: string
  image: string
  description: string
  icon: string
}

const ServiceCard = ({ service, ...props }: IProps) => {
  return (
    <Card>
      <CardContent style={{ textAlign: "start", minHeight: 300 }}>
        <i className={service.icon} style={{ float:'right',fontSize: 70, color: "rgb(24, 37, 51)", padding: 20 }}></i>
        <Typography variant="h6" color="rgb(24, 37, 51)"
        >
          {service.title}
        </Typography>
        <Typography className="body-font" variant="body2" component="p" color="rgb(24, 37, 51)">
          {service.description}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default ServiceCard
