import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "./bar"
import { Box } from "@material-ui/core"

const AboutPanel = () => {
  return (
    <div style={{ paddingTop: 30 }}>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" direction="column">
          <Typography style={{ display: "block" }} variant="h5">About us</Typography>
          <Bar />
        </Grid>
      </Box>
      <Grid container spacing={3} alignItems="center" style={{ minHeight: 500, paddingLeft: '10%', paddingRight: '10%', paddingTop: '2%' }}>
          <Typography variant="h4" color="inherit" >
            Kerala's Biggest campus in the Special Education and Therapy field
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 20, marginBottom: 10, color: "#154b68" }}
            className="body-font"
          >
            Jyothis Special School and Therapy Centre is an institution founded in 1994, to bridge the gaps in the developmental history of the children. The institution is the result of determination, hard work and thoughtful decisions made by our former President Bishops and Clergy Directors.
          </Typography>

          <Typography
            variant="subtitle1"
            style={{ marginBottom: 10, color: "#154b68" }}
            className="body-font"
          >
            We are proud to have the state’s biggest campus in the Special Education and Therapy field with a 25661 sq. ft. building, well equipped therapy centers, trained and passionate staff, parking area, children’s park, poultry and fish farms etc.
          </Typography>
          <div style={{ width: "100%", display: 'inline-block', marginTop: '20px',marginBottom: '30px'}}>
              <Image alt="campus (2).jpg" filename="campus (2).jpg" />
          </div>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 10, color: "#154b68" }}
            className="body-font"
          >
            Students admitted to our school follow the SCERT curriculum. The institution is registered in Kerala State Education Department and Social Justice Department. Though the institution is in the private management sector, we provide free education and therapeutic services like Speech therapy, Physiotherapy and Occupational therapy at subsidized rates. Our therapy centre is also open to all needy children among the general public.
          </Typography>

          <Grid container spacing={3} style={{ paddingBottom: 50, paddingTop: 50 }} alignItems="center" direction="column">
              <Typography style={{ display: "block" }} variant="h5">Our Services:</Typography>
              <Bar />
          </Grid>

        {/* <Hidden xsDown>
          <Grid
            item={true}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: 10 }}
          >
            
            <br />
            <div style={{ width: "94%", display: 'inline-block' }}>
              <Image
                alt="campus (3).jpg"
                filename="campus (3).jpg"
              />
            </div>
          </Grid>
        </Hidden> */}
        {/* <Grid
          item={true}
          xs={12}
          sm={6}
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          style={{ paddingLeft: 40, paddingRight: 40 }}
        >


        </Grid> */}
      </Grid>
    </div>
  )
}
export default AboutPanel
