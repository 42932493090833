import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"
import { navigate } from "gatsby"

const Services = () => {

  const handleRedirect = title => {
    navigate(`/departments#${title}`);
  };


  let services = [
    {
      id: 1,
      image: "search-doctor-icon.png",
      title: "Special Education",
      description:
        "Special school admission is provided for children who are below 18 years of age and have 40% or more disability. We follow the curriculum provided by National Institute of Mentally Handicapped, Hyderabad. The children appear for public equivalency exams after completing their preliminary class, 4th standard, 7th standard and 10th standard.",
      icon: "fas fa-book-reader"
    },
    {
      id: 2,
      image: "online-pharmacy-icon.png",
      title: "Vocational training",
      description:
        "Vocational training is an instructional program that prepares special children for an occupation. We provide vocational training program for special children above 18 years and help them acquire vocational skills which enhance their employment opportunities and subsequently help them to lead an independent life.",
      icon: "fas fa-puzzle-piece"
    },
    {
      id: 3,
      image: "consultation-icon.png",
      title: "Speech Therapy and Audiology",
      description:
        "Audiologists and Speech language pathologists deal with all the speech and language deficits, dysphagia and also assess the hearing threshold of the clients. Our clinicians provide therapy sessions for Autism spectrum disorder, Cerebral palsy, Down’s syndrome, Attention Deficit Hyperactivity Disorder and Fluency disorders.",
      icon: "fas fa-comments"
    },
    {
      id: 4,
      image: "details-info-icon.png",
      title: "Physiotherapy",
      description:
        "There are many conditions that require children to seek the help of a physiotherapist. Our qualified physiotherapists deal with all movement disorders, torticollis, posture issues, muscle tone issues, co-ordination issues, toe walking and other orthopedic conditions.",
      icon: "fas fa-dumbbell"
    },
    {
      id: 5,
      image: "emergency-care-icon.png",
      title: "Occupational Therapy",
      description:
        "Our trained occupational therapist helps people of all ages who have physical, sensory or cognitive problems. They also treat children with Autism spectrum disorder, Attention Deficit Hyperactivity disorder (ADHD), Down syndrome etc.",
      icon: "fas fa-briefcase-medical"
    }
  ]

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      style={{
        textAlign: "center",
        minHeight: 200, paddingLeft: '10%', paddingRight: '10%'
      }}
    >
      {services.map(service => (
        <Grid className="about-item" onClick={(event) => handleRedirect(service.title)} item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  )
}
export default Services
